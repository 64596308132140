// Use this to write your custom SCSS


.swiper-slide.bg-overlay-100:before {
    background: rgba($gray-900, 0.1);
  }
.swiper-hero, .swiper-hero .swiper-slide {
    height: 650px!important;
}


  .nav-link{
    color: #06386b;
    font-size: 18px;
  }


  .navbar-expand-lg.navbar-light .dropdown:not(.dropdown-submenu)>.dropdown-toggle:after {
    color: #06386b;
    }

   .navbar-expand-lg.navbar-light .offcanvas-body .dropdown:not(.dropdown-submenu)>.dropdown-toggle:after {
      color: #ffffff;
  }

    .navbar-expand-lg.navbar-light .dropdown:not(.dropdown-submenu)>.dropdown-toggle.active:after {
      color: #fac53a;
  }




.display-1, .display-4 {
  font-family: marydale, sans-serif!important;
font-weight: 900;
}

.underline-3:after {

    bottom: 0.1em;
}

